import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout pageId={"/dev-404-page"}>
    <div className="container-fluid bg-grey py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            404 dev page
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
